import React, { useState, useContext } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { AuthContext } from '../authContext';
import loginImg from '.././assets/loginImg.png';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const { isLoggedIn, handleLogin } = useContext(AuthContext);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');
  
    if (!username || !password) {
      setErrorMessage("Please enter both username and password.");
      setTimeout(() => setErrorMessage(''), 3000);
      return;
    }
  
    try {
      await handleLogin(username, password);
  
      if (isLoggedIn) {
        setSuccessMessage("Login successful!");
        setTimeout(() => {
          setSuccessMessage('');
          navigate('/home', { replace: true });
        }, 2000);
      } else {
        setErrorMessage("Login failed. Please ensure your credentials are correct and try again.");
        setTimeout(() => setErrorMessage(''), 3000);
      }
    } catch (error) {
      setErrorMessage("An unexpected error occurred. Please try again later.");
      setTimeout(() => setErrorMessage(''), 3000);
    }
  };  

  const loginBg = {
    backgroundImage: `url(${loginImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };

  if (isLoggedIn) {
    return <Navigate to="/home" replace />;
  }

  return (
    <div className='login-bg' style={loginBg}>
      <div className="login-form">
        <h2>Login</h2>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {successMessage && <div className="success-message">{successMessage}</div>}
        <form onSubmit={handleSubmit} >
          <div className="forms-group">
            <label htmlFor="username">Username</label><br />
            <input type="text" id="username" name="username" value={username} onChange={(e) => setUsername(e.target.value)} placeholder='Username' autoComplete="off" />
          </div>
          <div className="forms-group">
            <label htmlFor="password">Password</label><br />
            <input type="password" id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Password' autoComplete='off' />
          </div>
          <button type="submit" className='submitBtns'>Submit</button>
        </form>
      </div>
    </div>
  );
}

export default Login;
