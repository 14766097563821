import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BASE_URL } from '../config/constant';
import logo from '.././assets/logo.png';
import userIcon from '.././assets/usersIcon.svg';
import addIcon from '.././assets/addIcon.svg';
import incomeIcon from '.././assets/incomeIcon.svg';
import expenseIcon from '.././assets/expenseIcon.svg';
import balanceIcon from '.././assets/balanceIcon.svg';
import actionBtn from '.././assets/actionBtn.svg';
import downloadBtn from '.././assets/downloadBtn.svg';
import uploadFiles from '.././assets/uploadFile.svg';
import { Button, Form, Table, Row, Col, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Transaction.css';
import AdminPasswordPrompt from './AdminPasswordPrompt';
import { AuthContext } from '../authContext';

const AWS_REGION = 'ap-south-1';

function Transaction() {
    const [recentTransactions, setRecentTransactions] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [typeFilter, setTypeFilter] = useState('all');
    const [categoryFilter, setCategoryFilter] = useState('');
    const [transactionCount, setTransactionCount] = useState(10);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [newCategory, setNewCategory] = useState('');
    const [newSubCategory, setNewSubCategory] = useState('');
    const [showNewCategoryInput, setShowNewCategoryInput] = useState(false);
    const [showNewSubCategoryInput, setShowNewSubCategoryInput] = useState(false);
    const [showNewLocationInput, setShowNewLocationInput] = useState(false);
    const [showPasswordPrompt, setShowPasswordPrompt] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [location, setLocation] = useState('');
    const [locations, setLocations] = useState([]);
    const [newLocation, setNewLocation] = useState('');
    const [adminErrorMessage, setAdminErrorMessage] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { handleLogout } = useContext(AuthContext);
    const [username, setUsername] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/userInfo`, {
                    withCredentials: true
                });
                // console.log('Fetched User Info:', response.data);
                setUsername(response.data.username);
            } catch (error) {
                console.error('Error fetching user info:', error);
                if (error.response && error.response.status === 401) {
                    console.error('Unauthorized - redirecting to login.');
                    navigate('/login');
                }
            }
        };

        const fetchLocations = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/locations`, {
                    withCredentials: true
                });
                setLocations(response.data.locations);
                if (response.data.locations.length > 0) {
                    setLocation(response.data.locations[0]);
                }
            } catch (error) {
                console.error('Error fetching locations:', error);
                setErrorMessage('Failed to load locations. Please try again later.');
                setTimeout(() => setErrorMessage(''), 5000);
            }
        };

        fetchUserInfo();
        fetchLocations();
    }, [navigate,]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.logOut')) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const fetchRecentTransactions = useCallback(async () => {
        if (!location) return;

        try {
            const response = await axios.get(`${BASE_URL}/transactions/${location}`);
            const formattedTransactions = response.data.map(transaction => ({
                ...transaction,
                date: new Date(transaction.date).toISOString().split('T')[0]
            }));
            setRecentTransactions(formattedTransactions);
        } catch (error) {
            console.error('Error fetching recent transactions:', error);
            setErrorMessage('Failed to load recent transactions. Please try again later.');
            setTimeout(() => setErrorMessage(''), 5000);
        }
    }, [location]);

    const fetchCategoriesAndSubCategories = useCallback(async () => {
        if (!location) return;

        try {
            const response = await axios.get(`${BASE_URL}/categories/${location}`);
            setCategories(response.data.categories);
            setSubCategories(response.data.subCategories);
        } catch (error) {
            console.error('Error fetching categories and subcategories:', error);
            setErrorMessage('Failed to load categories and subcategories. Please try again later.');
            setTimeout(() => setErrorMessage(''), 5000);
        }
    }, [location]);

    useEffect(() => {
        if (location) {
            fetchRecentTransactions();
            fetchCategoriesAndSubCategories();
        }
    }, [location, fetchRecentTransactions, fetchCategoriesAndSubCategories]);

    const formatDateForDisplay = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString();
    };

    const formik = useFormik({
        initialValues: {
            date: new Date().toISOString().split('T')[0],
            type: '',
            amount: '',
            category: '',
            subCategory: '',
            description: '',
            files: []
        },
        validationSchema: Yup.object({
            date: Yup.string().required('Date is required'),
            type: Yup.string().required('Type is required'),
            amount: Yup.number()
                .required('Amount is required')
                .positive('Amount must be positive')
                .max(1000000, 'Amount cannot exceed 1,000,000'),
            category: Yup.string()
                .required('Category is required')
                .min(3, 'Category must be at least 3 characters')
                .max(50, 'Category cannot exceed 50 characters'),
            subCategory: Yup.string().max(50, 'Sub-Category cannot exceed 50 characters'),
            description: Yup.string().max(200, 'Description cannot exceed 200 characters'),
            files: Yup.array().of(
                Yup.mixed().test('fileSize', 'File too large', value => !value || value.size <= 5242880)
            )
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const formDataForRequest = new FormData();
                formDataForRequest.append('date', values.date);
                formDataForRequest.append('type', values.type);
                formDataForRequest.append('amount', values.amount);
                formDataForRequest.append('category', showNewCategoryInput ? newCategory : values.category);
                formDataForRequest.append('subCategory', showNewSubCategoryInput ? newSubCategory : values.subCategory);
                formDataForRequest.append('description', values.description);
                values.files.forEach(file => {
                    formDataForRequest.append('files', file);
                });
                // eslint-disable-next-line no-unused-vars
                const response = await axios.post(`${BASE_URL}/transactions/${location}`, formDataForRequest, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                resetForm();
                setNewCategory('');
                setNewSubCategory('');
                setShowNewCategoryInput(false);
                setShowNewSubCategoryInput(false);
                fetchRecentTransactions();
                fetchCategoriesAndSubCategories();
            } catch (error) {
                console.error('Error submitting transaction:', error);
                setErrorMessage('Failed to submit transaction. Please try again later.');
                setTimeout(() => setErrorMessage(''), 5000);
            }
        }
    });

    const exportToCSV = () => {
        const ws = XLSX.utils.json_to_sheet(filteredTransactions.map(transaction => ({
            Date: new Date(transaction.date).toLocaleDateString(),
            Type: transaction.type,
            Amount: transaction.amount,
            Category: transaction.category,
            'Sub-Category': transaction.subCategory,
            Description: transaction.description
        })));
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Transactions');
        XLSX.writeFile(wb, 'transactions.csv');
    };

    const handleUpdateClick = (transaction) => {
        if (username === "admin" || username === "nrs") {
            setSelectedTransaction(transaction);
            formik.setValues({
                date: new Date(transaction.date).toISOString().split('T')[0],
                type: transaction.type,
                amount: transaction.amount,
                category: transaction.category,
                subCategory: transaction.subCategory,
                description: transaction.description,
                files: []
            });
        } else {
            setShowPasswordPrompt(true);
            setSelectedTransaction(transaction);
        }
    };

    const handleCheckboxClick = async (transactionId, isVerified) => {
        try {
            await axios.put(`${BASE_URL}/transactions/${location}/${transactionId}/verify`, { isVerified });
            fetchRecentTransactions();
        } catch (error) {
            console.error('Error updating transaction verification status:', error);
        }
    };
    

    const handlePasswordConfirm = async (username, password) => {
        try {
            const response = await axios.post(`${BASE_URL}/api/admin/verifyPassword`, { username, password });
            if (response.data.success) {
                formik.setValues({
                    date: selectedTransaction.date,
                    type: selectedTransaction.type,
                    amount: selectedTransaction.amount,
                    category: selectedTransaction.category,
                    subCategory: selectedTransaction.subCategory,
                    description: selectedTransaction.description,
                    files: []
                });
                setShowNewCategoryInput(false);
                setShowNewSubCategoryInput(false);
                setShowPasswordPrompt(false);
            } else {
                setAdminErrorMessage(response.data.message);
                setTimeout(() => setAdminErrorMessage(''), 5000);
            }
        } catch (error) {
            console.error('Error verifying password:', error);
            setAdminErrorMessage('Failed to verify password. Please try again later.');
            setTimeout(() => setAdminErrorMessage(''), 5000);
        }
    };

    const handleCancelUpdate = () => {
        setSelectedTransaction(null);
        formik.resetForm();
        setShowNewCategoryInput(false);
        setShowNewSubCategoryInput(false);
        setShowPasswordPrompt(false);
    };

    const handleUpdateSubmit = async (event) => {
        event.preventDefault();
        try {
            const formDataForRequest = new FormData();
            formDataForRequest.append('date', formik.values.date);
            formDataForRequest.append('type', formik.values.type);
            formDataForRequest.append('amount', formik.values.amount);
            formDataForRequest.append('category', showNewCategoryInput ? newCategory : formik.values.category);
            formDataForRequest.append('subCategory', showNewSubCategoryInput ? newSubCategory : formik.values.subCategory);
            formDataForRequest.append('description', formik.values.description);
            formik.values.files.forEach(file => {
                formDataForRequest.append('files', file);
            });
            // eslint-disable-next-line no-unused-vars
            const response = await axios.put(`${BASE_URL}/transactions/${location}/${selectedTransaction.id}`, formDataForRequest, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setSelectedTransaction(null);
            formik.resetForm();
            setNewCategory('');
            setNewSubCategory('');
            setShowNewCategoryInput(false);
            setShowNewSubCategoryInput(false);
            fetchRecentTransactions();
            fetchCategoriesAndSubCategories();
            navigate('/transaction', { replace: true });
        } catch (error) {
            console.error('Error updating transaction:', error);
            setErrorMessage('Failed to update transaction. Please try again later.');
            setTimeout(() => setErrorMessage(''), 5000);
        }
    };

    const handleLocationChange = async (newLocation) => {
        if (!newLocation) return;

        await checkAndCreateTable(newLocation);
        setLocation(newLocation);
        fetchRecentTransactions();
    };

    const checkAndCreateTable = async (tableName) => {
        try {
            await axios.post(`${BASE_URL}/transactions/checkAndCreateTable`, { tableName });
        } catch (error) {
            console.error('Error creating new table:', error);
            setErrorMessage('Failed to create new table. Please try again later.');
            setTimeout(() => setErrorMessage(''), 5000);
        }
    };

    const handleAddLocation = async () => {
        if (showNewLocationInput && newLocation) {
            await handleLocationChange(newLocation);
            setLocations([...locations, newLocation]);
            setNewLocation('');
            setShowNewLocationInput(false);
            fetchRecentTransactions();
        } else {
            setShowNewLocationInput(true);
        }
    };

    const filteredTransactions = recentTransactions.filter(transaction => {
        if (startDate && transaction.date < startDate) return false;
        if (endDate && transaction.date > endDate) return false;
        if (typeFilter !== 'all' && transaction.type !== typeFilter) return false;
        if (categoryFilter && transaction.category !== categoryFilter) return false;
        if (searchKeyword && !transaction.description.toLowerCase().includes(searchKeyword.toLowerCase())) return false;
        return true;
    });

    const totalIncome = filteredTransactions.reduce((total, transaction) => {
        if (transaction.type === 'income') {
            return total + parseFloat(transaction.amount);
        }
        return total;
    }, 0);

    const totalExpense = filteredTransactions.reduce((total, transaction) => {
        if (transaction.type === 'expense') {
            return total + parseFloat(transaction.amount);
        }
        return total;
    }, 0);

    const totalBalance = totalIncome - totalExpense;

    return (
        <div className="transaction-page">
            {showPasswordPrompt && <AdminPasswordPrompt onConfirm={handlePasswordConfirm} onCancel={handleCancelUpdate} errorMessage={adminErrorMessage} username="nrs" />}
            <Row className='w100 headerContainer'>
                <Col className='p-0'>
                    <div className="headers">
                        <Col lg={2} className='logoSec'>
                            <div className="logo">
                                <img src={logo} alt="Logo" />
                            </div>
                        </Col>
                        <Col lg={9} className='munuContainer'>
                            <div className='menuLists'>
                                <div className="navigation-buttons">
                                    <Link to="/home" className="menuBtn navigation-button">Home</Link>
                                    <Link to="/transaction" className="menuBtn navigation-button active">Transaction</Link>
                                    <Link to="/weaver" className="menuBtn navigation-button">Weaver</Link>
                                    <Link to="/sareedesign" className="menuBtn navigation-button">Saree Design</Link>
                                    <Link to="/settings" className="menuBtn navigation-button">Settings</Link>
                                </div>
                                <div className='logOut' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                                    <p className='userName'>{username}</p>
                                    <div>
                                        <img src={userIcon} className='img-fluids' alt="User Icon" />
                                        <div className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
                                            <button onClick={() => handleLogout()} className="dropdown-item">Logout</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </div>
                </Col>
            </Row>
            <Row className='w100 mainContainer'>
                <Col sm={12} md={3} lg={3} className='leftSideContainer'>
                    <div className="location-buttons">
                        {showNewLocationInput && (
                            <Form.Control
                                type="text"
                                placeholder="Enter new location" className="mt-2" value={newLocation} onChange={(e) => setNewLocation(e.target.value)} />
                        )}
                        <Button className='addLocation' onClick={handleAddLocation}><img className='addIcon' src={addIcon} alt="Add Icon" />{showNewLocationInput ? 'Create Location' : 'Add Location'}</Button>
                    </div>
                    {selectedTransaction ? (
                        <div className="update-transaction">
                            <h2 className='text-center'>Update Transaction</h2>
                            <Form onSubmit={handleUpdateSubmit}>
                                <Form.Group>
                                    <Form.Label>Date:</Form.Label>
                                    <Form.Control type="date" name="date" value={formik.values.date} onChange={formik.handleChange} onBlur={formik.handleBlur} required max={new Date().toISOString().split('T')[0]} />
                                    {formik.touched.date && formik.errors.date && <Alert variant="danger">{formik.errors.date}</Alert>}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Type:</Form.Label>
                                    <Form.Control as="select" name="type" value={formik.values.type} onChange={formik.handleChange} onBlur={formik.handleBlur} required>
                                        <option value="">Select type</option>
                                        <option value="expense">Expense</option>
                                        <option value="income">Income</option>
                                    </Form.Control>
                                    {formik.touched.type && formik.errors.type && <Alert variant="danger">{formik.errors.type}</Alert>}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Amount:</Form.Label>
                                    <Form.Control type="tel" name="amount" value={formik.values.amount} onChange={formik.handleChange} onBlur={formik.handleBlur} required />
                                    {formik.touched.amount && formik.errors.amount && <Alert variant="danger">{formik.errors.amount}</Alert>}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Category:</Form.Label>
                                    <Form.Control as="select" name="category" value={formik.values.category} onChange={(e) => {
                                        formik.handleChange(e);
                                        setShowNewCategoryInput(e.target.value === 'new');
                                    }} onBlur={formik.handleBlur} required>
                                        <option value="">Select category</option>
                                        {categories.map(category => (
                                            <option key={category} value={category}>{category}</option>
                                        ))}
                                        <option value="new">Add new category</option>
                                    </Form.Control>
                                    {showNewCategoryInput && (
                                        <Form.Control type="text" className="mt-2" placeholder="Enter new category" value={newCategory} onChange={(e) => setNewCategory(e.target.value)} required />
                                    )}
                                    {formik.touched.category && formik.errors.category && <Alert variant="danger">{formik.errors.category}</Alert>}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Sub-Category:</Form.Label>
                                    <Form.Control as="select" name="subCategory" value={formik.values.subCategory} onChange={(e) => {
                                        formik.handleChange(e);
                                        setShowNewSubCategoryInput(e.target.value === 'new');
                                    }} onBlur={formik.handleBlur} required>
                                        <option value="">Select sub-category</option>
                                        {subCategories.map(subCategory => (
                                            <option key={subCategory} value={subCategory}>{subCategory}</option>
                                        ))}
                                        <option value="new">Add new sub-category</option>
                                    </Form.Control>
                                    {showNewSubCategoryInput && (
                                        <Form.Control type="text" className="mt-2" placeholder="Enter new sub-category" value={newSubCategory} onChange={(e) => setNewSubCategory(e.target.value)} required />
                                    )}
                                    {formik.touched.subCategory && formik.errors.subCategory && <Alert variant="danger">{formik.errors.subCategory}</Alert>}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Description:</Form.Label>
                                    <Form.Control as="textarea" name="description" value={formik.values.description} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {formik.touched.description && formik.errors.description && <Alert variant="danger">{formik.errors.description}</Alert>}
                                </Form.Group>
                                <Form.Group className="custom-upload-wrapper">
                                    <div className="upload-container">
                                        <Form.Label className="custom-upload">
                                            <img src={uploadFiles} alt="Upload File" className="upload-icon" />
                                            <span className="upload-text">Upload Files</span>
                                            <Form.Control
                                                type="file"
                                                name="files"
                                                multiple
                                                onChange={(event) => {
                                                    const filesArray = Array.from(event.target.files);
                                                    formik.setFieldValue('files', filesArray);
                                                    formik.setFieldValue('fileNames', filesArray.map(file => file.name).join(', '));
                                                }}
                                                className="hidden-file-input"
                                            />
                                        </Form.Label>
                                        {formik.values.fileNames && <span className="file-names">{formik.values.fileNames}</span>}
                                    </div>
                                    {formik.touched.files && formik.errors.files && <Alert variant="danger">{formik.errors.files}</Alert>}
                                </Form.Group>
                                <Button type="submit" className='mt-3 submitBtn'>Update</Button>
                                <Button type="button" className="mt-3 cancelBtn" onClick={handleCancelUpdate}>Cancel</Button>
                            </Form>
                        </div>
                    ) : (
                        <div className="add-transaction">
                            <div className="transaction-form">
                                <h2 className='transHeading text-center'>Add Transaction</h2>
                                {formik.errors.general && <Alert variant="danger">{formik.errors.general}</Alert>}
                                <Form className='form' onSubmit={formik.handleSubmit}>
                                    <Form.Group>
                                        <Form.Label htmlFor='date'>Date:</Form.Label>
                                        <Form.Control type="date" id='date' name="date" value={formik.values.date} readOnly />
                                        {formik.touched.date && formik.errors.date && <Alert variant="danger">{formik.errors.date}</Alert>}
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label htmlFor='type'>Type:</Form.Label>
                                        <Form.Control as="select" id='type' name="type" value={formik.values.type} onChange={formik.handleChange} onBlur={formik.handleBlur} required>
                                            <option value="">Select type</option>
                                            <option value="expense">Expense</option>
                                            <option value="income">Income</option>
                                        </Form.Control>
                                        {formik.touched.type && formik.errors.type && <Alert variant="danger">{formik.errors.type}</Alert>}
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label htmlFor='amount'>Amount:</Form.Label>
                                        <Form.Control type="tel" id='amount' name="amount" value={formik.values.amount} onChange={formik.handleChange} onBlur={formik.handleBlur} required />
                                        {formik.touched.amount && formik.errors.amount && <Alert variant="danger">{formik.errors.amount}</Alert>}
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label htmlFor='category'>Category:</Form.Label>
                                        <Form.Control as="select" id='category' name="category" value={formik.values.category} onChange={(e) => {
                                            formik.handleChange(e);
                                            setShowNewCategoryInput(e.target.value === 'new');
                                        }} onBlur={formik.handleBlur} required>
                                            <option value="" >Select category</option>
                                            {categories.map(category => (
                                                <option key={category} value={category} className='dropdown-item'>{category}</option>
                                            ))}
                                            <option value="new">Add new category</option>
                                        </Form.Control>
                                        {showNewCategoryInput && (
                                            <Form.Control type="text" className="mt-2" placeholder="Enter new category" value={newCategory} onChange={(e) => setNewCategory(e.target.value)} required />
                                        )}
                                        {formik.touched.category && formik.errors.category && <Alert variant="danger">{formik.errors.category}</Alert>}
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label htmlFor='subCategory'>Sub-Category:</Form.Label>
                                        <Form.Control as="select" id='subCategory' name="subCategory" value={formik.values.subCategory} onChange={(e) => {
                                            formik.handleChange(e);
                                            setShowNewSubCategoryInput(e.target.value === 'new');
                                        }} onBlur={formik.handleBlur} required>
                                            <option value="">Select sub-category</option>
                                            {subCategories.map(subCategory => (
                                                <option key={subCategory} value={subCategory}>{subCategory}</option>
                                            ))}
                                            <option value="new">Add new sub-category</option>
                                        </Form.Control>
                                        {showNewSubCategoryInput && (
                                            <Form.Control type="text" className="mt-2" placeholder="Enter new sub-category" value={newSubCategory} onChange={(e) => setNewSubCategory(e.target.value)} required />
                                        )}
                                        {formik.touched.subCategory && formik.errors.subCategory && <Alert variant="danger">{formik.errors.subCategory}</Alert>}
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label htmlFor='description'>Description:</Form.Label>
                                        <Form.Control as="textarea" id='description' name="description" value={formik.values.description} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                        {formik.touched.description && formik.errors.description && <Alert variant="danger">{formik.errors.description}</Alert>}
                                    </Form.Group>
                                    <Form.Group className="custom-upload-wrapper">
                                        <div className="upload-container">
                                            <Form.Label className="custom-upload">
                                                <img src={uploadFiles} alt="Upload File" className="upload-icon" />
                                                <span className="upload-text">Upload Files</span>
                                                <Form.Control type="file" name="files" multiple
                                                    onChange={(event) => {
                                                        const filesArray = Array.from(event.target.files);
                                                        console.log(filesArray.map(file => file.name));
                                                        formik.setFieldValue('files', filesArray);
                                                        formik.setFieldValue('fileNames', filesArray.map(file => file.name).join(', '));
                                                    }}
                                                    className="hidden-file-input" />
                                            </Form.Label>
                                            {formik.values.fileNames && <span className="file-names">{formik.values.fileNames}</span>}
                                        </div>
                                        {formik.touched.files && formik.errors.files && <Alert variant="danger">{formik.errors.files}</Alert>}
                                    </Form.Group>
                                    <Button type="submit" className='mt-2 submitBtn'>Submit</Button>
                                </Form>
                            </div>
                        </div>
                    )}
                </Col>
                <Col sm={12} md={9} lg={9} className='rightSideContainer'>
                    <div className='contanerShad'>
                        <div className='locationContainer'>
                            <div className='locationBtns'>
                                {locations.map(loc => (
                                    <Button key={loc} variant={location === loc ? 'active' : 'info'} className="locBtn" onClick={() => handleLocationChange(loc)}>{loc.charAt(0).toUpperCase() + loc.slice(1)}</Button>
                                ))}
                            </div>
                            <div className='heading'>
                                <h1>{location.charAt(0).toUpperCase() + location.slice(1)} Transactions</h1>
                            </div>
                        </div>

                        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                        <div className="filters">
                            <div className="filterGrp">
                                <Form.Group as={Row} className="filterRow">
                                    <Form.Label column>Start Date:</Form.Label>
                                    <Col>
                                        <Form.Control
                                            type="date"
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="filterRow">
                                    <Form.Label column>End Date:</Form.Label>
                                    <Col>
                                        <Form.Control
                                            type="date"
                                            value={endDate}
                                            onChange={(e) => setEndDate(e.target.value)}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="filterRow">
                                    <Form.Label column>Type:</Form.Label>
                                    <Col>
                                        <Form.Control
                                            as="select"
                                            value={typeFilter}
                                            onChange={(e) => setTypeFilter(e.target.value)}
                                        >
                                            <option value="all">All</option>
                                            <option value="income">Income</option>
                                            <option value="expense">Expense</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="filterRow">
                                    <Form.Label column>Category:</Form.Label>
                                    <Col>
                                        <Form.Control
                                            as="select"
                                            value={categoryFilter}
                                            onChange={(e) => setCategoryFilter(e.target.value)}
                                        >
                                            <option value="">All Categories</option>
                                            {categories.map((category) => (
                                                <option key={category} value={category}>
                                                    {category}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="filterRow">
                                    <Form.Label column>Search:</Form.Label>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            value={searchKeyword}
                                            onChange={(e) => setSearchKeyword(e.target.value)}
                                            placeholder="Search description"
                                            className="search-input"
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="filterRow">
                                    <Form.Label column>Show Last:</Form.Label>
                                    <Col>
                                        <Form.Control
                                            as="select"
                                            value={transactionCount}
                                            onChange={(e) => setTransactionCount(e.target.value)}
                                        >
                                            <option value="10">10 Transactions</option>
                                            <option value="20">20 Transactions</option>
                                            <option value="all">All Transactions</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>

                                <Button onClick={exportToCSV} className="exCSV">
                                    Export to CSV
                                </Button>
                            </div>
                        </div>
                        <div className="totals">
                            {typeFilter === 'all' && <h4 className='amountCard incomeCard'> <img src={incomeIcon} alt="Income Icon" />Total Income:<br /><b>₹{totalIncome}</b></h4>}
                            {typeFilter === 'all' && <h4 className='amountCard expenseCard'><img src={expenseIcon} alt="Expense Icon" />Total Expense:<br /><b>₹{totalExpense}</b></h4>}
                            {typeFilter === 'all' && <h4 className='amountCard balanceCard'><img src={balanceIcon} alt="Balance Icon" />Total Balance: <br /><b>₹{totalBalance}</b></h4>}
                            {typeFilter === 'income' && <h4 className='amountCard incomeCard'><img src={incomeIcon} alt="Income Icon" />Total Income: <br /><b>₹{totalIncome}</b></h4>}
                            {typeFilter === 'expense' && <h4 className='amountCard expenseCard'><img src={expenseIcon} alt="Expense Icon" />Total Expense: <br /><b>₹{totalExpense}</b></h4>}
                        </div>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Amount</th>
                                    <th>Category</th>
                                    <th>Sub-Category</th>
                                    <th>Description</th>
                                    <th>Files</th>
                                    {username === "admin" || username === "nrs" ? <th>Action</th> : null}
                                </tr>
                            </thead>
                            <tbody>
                                {filteredTransactions.sort((a, b) => new Date(b.date) - new Date(a.date)).slice(0, transactionCount === 'all' ? filteredTransactions.length : transactionCount).map(transaction => (
                                    <tr key={transaction.id}>
                                        <td>{formatDateForDisplay(transaction.date)}</td>
                                        <td className={transaction.type === 'income' ? 'income-type' : 'expense-type'}>{transaction.type}</td>
                                        <td>₹ {transaction.amount}</td>
                                        <td>{transaction.category}</td>
                                        <td>{transaction.subCategory}</td>
                                        <td>{transaction.description}</td>
                                        <td>
                                            {transaction.file && (
                                                <a href={`https://newrainsarees.s3.${AWS_REGION}.amazonaws.com/transactions/${transaction.file}`} className="btn" download><img src={downloadBtn} alt='download' className='img-fluid' /></a>
                                            )}
                                        </td>
                                        {username === "admin" || username === "nrs" ? (
                                            <td className='actionCell'>
                                                <div className="actionContainer">
                                                    <Button className='actionBtn' onClick={() => handleUpdateClick(transaction)}>
                                                        <img src={actionBtn} className='actionBtnIcon' alt="Update Icon" />
                                                        Update
                                                    </Button>
                                                    <input type="checkbox"
                                                        className="action-checkbox"
                                                        checked={transaction.isVerified}
                                                        onChange={() => handleCheckboxClick(transaction.id, !transaction.isVerified)} />
                                                </div>
                                            </td>
                                        ) : null}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Transaction;
